:root {
  --font-red: 30;
  --font-green: 30;
  --font-blue: 30;
  --title-red: 30;
  --title-green: 30;
  --title-blue: 30;
  --background-red: 240;
  --background-green: 240;
  --background-blue: 240;
  --modal-background-red: 200;
  --modal-background-green: 200;
  --modal-background-blue: 200;
  --box-background-red: 220;
  --box-background-green: 220;
  --box-background-blue: 220;
  --box-font-red: 0;
  --box-font-green: 0;
  --box-font-blue: 0;
  --button-background-red: 220;
  --button-background-green: 220;
  --button-background-blue: 220;
  --button-font-red: 0;
  --button-font-green: 0;
  --button-font-blue: 0;

  --background-color: var(--background-red), var(--background-green), var(--background-blue); // light grey
  --modal-background-color: var(--modal-background-red), var(--modal-background-green), var(--modal-background-blue); // white
  --box-background-color: var(--box-background-red), var(--box-background-green), var(--box-background-blue); // light grey
  --box-font-color: var(--box-font-red), var(--box-font-green), var(--box-font-blue); // light grey
  --text-color: var(--font-red), var(--font-green), var(--font-blue); // dark grey
  --title-color: var(--title-red), var(--title-green), var(--title-blue); // dark grey
  --button-background-color: var(--button-background-red), var(--button-background-green), var(--button-background-blue); // light grey
  --button-font-color: var(--button-font-red), var(--button-font-green), var(--button-font-blue); // light grey

  --font-accessible-color: calc(
    ((((var(--font-red) * 299) +
    (var(--font-green) * 587) +
    (var(--font-blue) * 114)) / 1000) - 128) * -1000
  );

  --title-accessible-color: calc(
    ((((var(--title-red) * 299) +
    (var(--title-green) * 587) +
    (var(--title-blue) * 114)) / 1000) - 128) * -1000
  );

  --box-font-accessible-color: calc(
    ((((var(--box-font-red) * 299) +
    (var(--box-font-green) * 587) +
    (var(--box-font-blue) * 114)) / 1000) - 128) * -1000
  );

  --button-font-accessible-color: calc(
    ((((var(--button-font-red) * 299) +
    (var(--button-font-green) * 587) +
    (var(--button-font-blue) * 114)) / 1000) - 128) * -1000
  );

  --text-shadow-color: var(--font-accessible-color), var(--font-accessible-color), var(--font-accessible-color);
  --title-shadow-color: var(--title-accessible-color), var(--title-accessible-color), var(--title-accessible-color);
  --box-text-shadow-color: var(--box-font-accessible-color), var(--box-font-accessible-color), var(--box-font-accessible-color);
  --button-text-shadow-color: var(--button-font-accessible-color), var(--button-font-accessible-color), var(--button-font-accessible-color);

  --button-background-color-lighter: hsl(var(--button-background-h), var(--button-background-s), calc(var(--button-background-l) * 0.8));
  --box-background-color-lighter: hsl(var(--box-background-h), var(--box-background-s), calc(var(--box-background-l) * 0.9));

  font-size: var(--expo-font-size);
  font-family: "Mulish", sans-serif;
  font-weight: 300;
}

@media screen and (max-width: 767px) {
  :root {
    font-size: calc(var(--expo-font-size) * 1.5);
  }
}

@media only screen and (max-width: 575px) {
  :root {
    font-size: calc(var(--expo-font-size) * 3);
  }
}

body {
  background-color: rgba(var(--background-color), 1);
  color: rgba(var(--text-color), 1);
  padding: 0;
  margin: 0;
}

.invert {
  filter: invert(100%);
}

.hero-blk {
  // padding: 12rem 0 12rem 0;
  padding: 12rem 0 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &.hero-blk-desc {
    padding: 6rem 0 6rem 0;
  }

  &.hero-padding-small {
    padding: 4rem 0 4rem 0;
  }

  .container {
    margin: 0 auto;
    max-width: 1300px;
    width: 100%;
    position: relative;

    .hero-blk-wrap {
      //max-width: 720px;
      width: 100%;
      margin: 0 auto;
      position: relative;
      z-index: 2;

      &.text-frame {
        //background: rgba(var(--box-background-color), 0.6);
        padding: 2vw;
        //-webkit-box-shadow: 0 1px 2px rgba(var(--box-font-color), 0.15);
        //-moz-box-shadow: 0 1px 2px rgba(var(--box-font-color), 0.15);
        //box-shadow: 0 1px 2px rgba(var(--box-font-color), 0.15);
        //-webkit-border-radius: 5px;
        //-moz-border-radius: 5px;
        //-ms-border-radius: 5px;
        //-o-border-radius: 5px;
        //border-radius: 5px;

        box-shadow: inset 0px 1px 3px 0px #91b8b3;
        background: linear-gradient(to bottom, rgba(var(--box-background-color), 1) 5%, var(--box-background-color-lighter) 100%);
        background-color: rgba(var(--box-background-color), 1);
        border-radius: 1em;
        border: 1 px  solid rgba(var(--box-background-color), 0.5);
        text-shadow: 0 0 5px rgba(var(--box-text-shadow-color), 1) !important;
        color: rgba(var(--box-font-color), 1);

        .hero-font {
          color: rgba(var(--box-font-color), 1);
          text-shadow: 0 0 5px rgba(var(--box-text-shadow-color), 1) !important;
        }
      }

      &.hero-blk-wrap-intro {
        max-width: 720px;
        width: 100%;
      }

      &.hero-blk-wrap-wide {
        max-width: 100%;
        width: 100%;
      }

      .hero-font {
        text-shadow: 0 0 5px rgba(var(--text-shadow-color), 1);
      }

      .hero-font-intro, .hero-font-desc {
        .head {
          font-size: 3rem;
          line-height: 1.25em;
          letter-spacing: 0.05em;
          font-weight: bold;
          text-align: center;
          margin-bottom: 20px;
        }

        .lead {
          font-size: 2rem;
          line-height: 1.1em;
          letter-spacing: 0.3rem;
          margin-bottom: 2rem;
          text-align: center;
          font-weight: bolder;

          &.lead-side {
            margin-left: 5vw;
          }
        }

        .content {
          font-size: 1.2rem;
          margin-bottom: 2rem;
          font-weight: bold;
        }
      }
    }
  }

  .cta-blk {
    text-align: center;
    display: flex;
    justify-content: center;

    .btn {
      margin: 0 1rem;
    }
  }

  :after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    pointer-events: none;
  }
}

//
.expo-blk-wrap {
  .expo-item {
    box-shadow: 0 8px 35px 10px rgba(0, 0, 0, 0.07);

    .expo-logo {
      background-color: #ffffff;
    }

    .expo-item-wrap {
      background-color: #ffffff;
      padding: 1rem;

      h3 {
        font-size: 1.5rem;
        letter-spacing: 0.2px;
        margin: 1.5vw 0 1vw 0;
        height: 3.5rem;
        overflow-y: hidden;
        overflow-wrap: anywhere;
        margin-block: 0;
      }

      .description {
        height: 3rem;
        max-height: 3vw;
        font-size: 0.9rem;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .date {
        font-size: 0.8rem;
        text-transform: uppercase;
        font-family: "Barlow", sans-serif;
      }
    }
  }
}

.home-page {
}

.expo-page {
}

.font-inverse {
  color: white;
  text-shadow: #0b0b0b;
}

.exhibitor-page, .stage-page {
  .box-link {
    a, a:hover, a:focus {
      //font-variant: all-petite-caps;
      //color: rgba(var(--font-color), 1);

    }
  }

  .linkList {
    font-size: 0.8rem;
  }

  .text-frame {
    margin: 0 0 1vw 0;
    padding: 2vw;

    box-shadow: inset 0px 1px 3px 0px #91b8b3;
    background: linear-gradient(to bottom, rgba(var(--box-background-color), 1) 5%, var(--box-background-color-lighter) 100%);
    background-color: rgba(var(--box-background-color), 1);
    border-radius: 1em;
    border: 1 px  solid rgba(var(--box-background-color), 0.5);
    text-shadow: 0 0 5px rgba(var(--box-text-shadow-color), 1) !important;
    color: rgba(var(--box-font-color), 1);

    a, a:hover, a:focus {
      //font-variant: all-petite-caps;
      // color: rgba(var(--box-font-color), 1);
    }
  }

  .exhibitor-contact {
    font-size: 0.9em;

    .box-link {
      a, a:hover, a:focus {
        //font-variant: all-petite-caps;
        //color: rgba(var(--box-font-color), 1);
      }
    }
  }

  .media-item {
    font-size: 0.9rem;
    margin-bottom: 1em;
  }
}

.calendar-entry {
  margin: 0.5vw;
  box-shadow: 0px 8px 35px 10px rgba(0, 0, 0, 0.07);
  background-color: rgba(var(--box-background-color), 1);
  color: rgba(var(--box-font-color), 1);
  overflow: hidden;
  position: relative;
  font-size: 0.75em;
  font-weight: 200;

  .image {
    float: right;
    width: 12vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .content {
    float: left;
    width: calc(100% - 12vw);
    padding: 1vh 1vw 1.1em;
    font-size: 1.1em;
    line-height: 1;
    position: relative;
    min-height: 9vw;

    &.content-wide {
      width: 100%;
    }

    .date {
      margin-bottom: 1.15em;
      font-size: 0.8em;
      line-height: 1em;
      font-family: "Barlow", sans-serif;
      font-weight: 300;
    }

    .title {
      font-weight: 600;
      margin-bottom: 9px;
    }

    .description {
      font-weight: 300;
    }
  }

  @media (max-width: 985px) {
    .image {
      width: 100%;
      //height: auto;
      //padding-bottom: 56.6%;
    }

    .content {
      width: 100%;
      //padding: 20px 10px 29px;
    }
  }

  .download {
    position: absolute;
    bottom: 0.5vw;
    right: 0.5vw;
  }
}


a, a:hover, a:focus {
  // color: rgba(var(--box-font-color), 1);
}


// Extra size
.btn-xs {
  padding: 0.0625rem 0.3125rem;
  font-size: 0.8125rem;
  line-height: 1.5;
}

.btn-group-xs {
  .btn {
    padding: 0.0625rem 0.3125rem;
    font-size: 0.8125rem;
    line-height: 1.5;
  }
}

a.close {
  cursor: pointer;

  &:hover {
    color: red;
  }
}

.privacy-link {
  font-size: 0.8em;
  font-family: "Barlow", sans-serif;
  font-weight: 300;
}

strong {
  font-weight: 800;
}

.modal-header {
  background-color: rgba(var(--box-background-color), 1);
  color: rgba(var(--box-font-color), 1);
  text-shadow: 0 0 5px rgba(var(--box-text-shadow-color), 1) !important;
  border-bottom: none;
}

.modal-body {
  background-color: rgba(var(--box-background-color), 0.9);
  color: rgba(var(--box-font-color), 1);
  text-shadow: 0 0 5px rgba(var(--box-text-shadow-color), 1) !important;
}

.chatNameFrame, .chatInputFrame {
  background-color: rgba(var(--box-background-color), 1);
  color: rgba(var(--box-font-color), 1);
  text-shadow: 0 0 5px rgba(var(--box-text-shadow-color), 1) !important;
}

.svgThemeBorder {
  fill: rgba(var(--box-background-color), 1);
}


.lead {
  font-size: var(--expo-intro-font-size);
}

@media screen and (max-width: 575px) {
  .exhibitor-page, .stage-page {
    .exhibitor-contact {
      font-size: 0.5em;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
}

@media screen and (min-width: 768px) and (max-width: 991px) {
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
}

@media screen and (min-width: 1200px) and (max-width: 1199px) {
}

@media screen and (max-width: 1200px) {
  h1 {
    font-size: calc(1.375em + 1.5vw);
  }
  h2 {
    font-size: calc(var(--expo-font-size) * 2);
  }
  h3 {
    font-size: calc(1.3em + 0.6vw);
  }
  h4 {
    font-size: calc(1.275em + 0.3vw);
  }
  h5 {
    font-size: 1.25em;
  }
  h6 {
    font-size: 1em;
  }
}

@media screen and (min-width: 1200px) {
  h1 {
    font-size: 2.5em;
  }
  h2 {
    //font-size: 2em;
    font-size: calc(var(--expo-font-size) * 2);
  }
  h3 {
    font-size: 1.75em;
  }
  h4 {
    font-size: 1.5em;
  }
  h5 {
    font-size: 1.25em;
  }
  h6 {
    font-size: 1em;
  }
}

@media screen and (min-width: 768px) {
  .box-link {
    .link-long {
      font-size: 0.85em;
    }
  }
}

.external-url {
  font-variant: all-petite-caps;
}

img[src*="#floatinglogo"] {
  max-width: 10vw;
  float: right;
}
