$enable-responsive-font-sizes: true;

$body_color: #093E5E;
$black: #093E5E;
$alternate_color: #0069AB;

@import "~bootstrap-scss/bootstrap";
//@import "~bootstrap-scss/functions";
//@import "~bootstrap-scss/variables";
//@import "~bootstrap-scss/mixins";
//@import "~bootstrap-scss/root";
//@import "~bootstrap-scss/reboot";
//@import "~bootstrap-scss/type";
//@import "~bootstrap-scss/images";
//@import "~bootstrap-scss/code";
//@import "~bootstrap-scss/grid";
//@import "~bootstrap-scss/tables";
//@import "~bootstrap-scss/forms";
//@import "~bootstrap-scss/buttons";
//@import "~bootstrap-scss/transitions";
//@import "~bootstrap-scss/dropdown";
//@import "~bootstrap-scss/button-group";
//@import "~bootstrap-scss/input-group";
//@import "~bootstrap-scss/custom-forms";
//@import "~bootstrap-scss/nav";
//@import "~bootstrap-scss/navbar";
//@import "~bootstrap-scss/card";
//@import "~bootstrap-scss/breadcrumb";
//@import "~bootstrap-scss/pagination";
//@import "~bootstrap-scss/badge";
//@import "~bootstrap-scss/jumbotron";
//@import "~bootstrap-scss/alert";
//@import "~bootstrap-scss/progress";
//@import "~bootstrap-scss/media";
//@import "~bootstrap-scss/list-group";
//@import "~bootstrap-scss/close";
//@import "~bootstrap-scss/toasts";
//@import "~bootstrap-scss/modal";
//@import "~bootstrap-scss/tooltip";
//@import "~bootstrap-scss/popover";
//@import "~bootstrap-scss/carousel";
//@import "~bootstrap-scss/spinners";
//@import "~bootstrap-scss/utilities";
//@import "~bootstrap-scss/print";


@import "assets/style.scss";
@import "assets/responsive.css";

@import '~@fortawesome/fontawesome-free/css/brands.css';
@import '~@fortawesome/fontawesome-free/css/regular.css';
@import '~@fortawesome/fontawesome-free/css/solid.css';
@import '~@fortawesome/fontawesome-free/css/fontawesome.css';

@import '~ngx-ui-switch/ui-switch.component.scss';
@import '~ngx-toastr/toastr.css';

.chatlog {
  width: 100%;
}

td.chatname {
  width: 100px;
  overflow-x: hidden;
}

td.chatname {
  width: 100px;
  overflow-x: hidden;
}

th.chatmessage {
}

td.chatmessage {
}

.chatArea {
  max-height: 400px;
  height: 400px;
  overflow: hidden;
}

/* You can add global styles to this file, and also import other style files */
.chatlog {
  width: 100%;
}

td.chatname {
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  overflow-x: hidden;
  vertical-align: top;
}

th.chatname {
  width: 100px;
  overflow-x: hidden;
}

th.chatmessage {
}

td.chatmessage {
  width: 100%;
}

.chatWrapper {
  height: 400px;
  min-height: 400px;
  max-height: 400px;
  vertical-align: top;
  overflow-x: hidden;
  overflow-y: hidden;
}

.chatLabel {
  font-size: 0.8em;
  padding-right: 1em;
}

.chatEnter {
  width: 400px;
}

.chatArea {
  max-height: 65%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.chatMessage .chatName {
  padding-right: 1em;
  font-weight: bold;
  width: 10em;
}


.linkList li {
  padding-left: 2em;
  text-indent: -1em;
}

.svgMediaImage {
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.introjs-overlay {
  z-index: 1000 !important;
}

.introjs-tooltipReferenceLayer {
  z-index: 1001 !important;
}

.introjs-helperLayer {
  z-index: 999 !important;
}

.introjs-showElement, tr.introjs-showElement > td, tr.introjs-showElement > th {
  z-index: 1001 !important;
}

.centered-foreign {
  margin: auto;
}
